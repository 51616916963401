import { Divider, IconButton, Menu, MenuButton, MenuList, Box, Accordion, HStack, Text } from "@chakra-ui/react";
import { ROUTES } from "../Routes";
import { IconMenu2 } from "@tabler/icons-react";
import { useAuthenticatedUser } from "../hooks";
import { Link, SignOutLink, MenuItemDropDown } from "../components";
import { generatePath } from "react-router-dom";
import { MenuItem } from "./ApplicationSideBar";
import { DocumentationMenu } from "./DocumentationMenu";
import { IconFlag2 } from "@tabler/icons-react";

export const HamburgerMenu = () => {
    const { isCloudOpsRole, isOwnerRole, orgId, isInternalRole } = useAuthenticatedUser();

    return (
        <Box>
            <Menu>
                <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<IconMenu2 stroke={"1.25"} />}
                    variant="outline"
                    display={{ base: "flex", md: "none" }}
                />
                <MenuList overflowY="auto" maxHeight="92vh" pb="spacer-2">
                    {isCloudOpsRole ? (
                        <>
                            <MenuItem to={ROUTES.HOME}>
                                <Link to={ROUTES.HOME}>Home</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.LIST_STORES}>
                                <Link to={ROUTES.LIST_STORES}>Stores</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.STATUS_PAGE}>
                                <Link to={ROUTES.STATUS_PAGE}>Nostra Status</Link>
                            </MenuItem>
                            <Accordion allowToggle>
                                <MenuItemDropDown
                                    menuItems={[
                                        <MenuItem to={generatePath(ROUTES.ORGANIZATION, { orgId })}>
                                            <Link to={generatePath(ROUTES.ORGANIZATION, { orgId })}>
                                                My Organization
                                            </Link>
                                        </MenuItem>,
                                        <MenuItem to={ROUTES.ALL_ORGANIZATIONS}>
                                            <Link to={ROUTES.ALL_ORGANIZATIONS}>All Organizations</Link>
                                        </MenuItem>,
                                    ]}
                                >
                                    Organizations
                                </MenuItemDropDown>
                            </Accordion>
                            <DocumentationMenu />
                            <MenuItem to={ROUTES.CREATE_CUSTOMER}>
                                <Link to={ROUTES.CREATE_CUSTOMER}>New Customer</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.LIST_BULLETINS_PAGE}>
                                <Link to={ROUTES.LIST_BULLETINS_PAGE}>Bulletins</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.INSTALLED_APPS}>
                                <Link to={ROUTES.INSTALLED_APPS}>Installed Apps</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.MONITORING_PAGE}>
                                <Link to={ROUTES.MONITORING_PAGE}>Edge Monitoring</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.STORE_REPORTS}>
                                <Link to={ROUTES.STORE_REPORTS}>Reports</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.ALL_STORES_REPORT}>
                                <Link to={ROUTES.ALL_STORES_REPORT}>All Stores Report</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.LIST_INTEGRATIONS_PAGE}>
                                <Link to={ROUTES.LIST_INTEGRATIONS_PAGE}>Integrations</Link>
                            </MenuItem>
                        </>
                    ) : (
                        <>
                            <MenuItem to={ROUTES.HOME}>
                                <Link to={ROUTES.HOME}>Home</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.LIST_STORES}>
                                <Link to={ROUTES.LIST_STORES}>Stores</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.STATUS_PAGE}>
                                <Link to={ROUTES.STATUS_PAGE}>Nostra Status</Link>
                            </MenuItem>
                            {isOwnerRole && (
                                <MenuItem to={generatePath(ROUTES.ORGANIZATION, { orgId })}>
                                    <Link to={generatePath(ROUTES.ORGANIZATION, { orgId })}>Organization</Link>
                                </MenuItem>
                            )}
                            <DocumentationMenu />
                            {isInternalRole && (
                                <MenuItem to={ROUTES.ALL_STORES_REPORT}>
                                    <Link to={ROUTES.ALL_STORES_REPORT}>All Stores Report</Link>
                                </MenuItem>
                            )}
                        </>
                    )}
                    <MenuItem to={ROUTES.GET_HELP_NOW}>
                        <Link to={ROUTES.GET_HELP_NOW}>
                            <HStack justifyContent={"start"}>
                                <IconFlag2 stroke={"1.25"} fill={"red"} />
                                <Text textStyle={"text-body-display-XS"}>Troubleshoot</Text>
                            </HStack>
                        </Link>
                    </MenuItem>
                    <MenuItem isExternal to={ROUTES.SUPPORT}>
                        <Link target="_blank" to={ROUTES.SUPPORT}>
                            Contact Support
                        </Link>
                    </MenuItem>
                    <Divider />
                    {isCloudOpsRole || isOwnerRole ? (
                        <MenuItem to={ROUTES.LIST_USERS}>
                            <Link to={ROUTES.LIST_USERS}>Manage Users</Link>
                        </MenuItem>
                    ) : null}
                    <MenuItem to={""}>
                        <SignOutLink />
                    </MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};
