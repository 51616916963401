import { SVC_URLS } from "../../config/SVC_URLS";
import { handleErrors, fetchOptions, fetchResponse } from "../../lib";
import { useMutation } from "@tanstack/react-query";
import { UpdateStoreIntegration } from "../../types/requests";

export const useStoreIntegrationMutation = (storeId: string, customerUrl: string, id: string) => {
    return useMutation({
        mutationFn: updateStoreIntegration(storeId, customerUrl, id),
    });
};

const updateStoreIntegration =
    (storeId: string, customerUrl: string, id: string) => async (data: UpdateStoreIntegration) => {
        const url = `${SVC_URLS["portal"]}/${storeId}/integrations/${id}`;
        const options = await fetchOptions({
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                Store: customerUrl,
            },
        });
        const response = await fetch(url, options).catch(handleErrors);
        return fetchResponse(response);
    };
