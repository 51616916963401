import {
    Stack,
    Text,
    Flex,
    HStack,
    Box,
    Avatar,
    useToast,
    Badge,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from "@chakra-ui/react";
import { PageContainer } from "../PageContainer";
import { Header, Search, SubHeader, Link } from "../../components";
import { LoadingPage } from "../LoadingPage";
import { useState, useEffect } from "react";
import { useListStoresPerIntegration } from "../../hooks";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { formatDateTimeDisplay } from "../../lib";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { StorePerIntegration } from "../../types";

const searchIntegrationsFilter = (integration: StorePerIntegration, searchTerm: string) => {
    return integration.store.customerUrl.toLowerCase().includes(searchTerm);
};

export const ListIntegrationByStore = () => {
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const [filteredIntegrations, setFilteredIntegrations] = useState<StorePerIntegration[]>([]);
    const { data: storeIntegrations, isLoading, isError } = useListStoresPerIntegration();

    useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });
    const showToast = useToast();

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description: "An unexpected error has occurred. If the problem persists, please contact support.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    if (isLoading) return <LoadingPage />;
    if (!storeIntegrations) return <SubHeader>No Store Integrations Found</SubHeader>;

    return (
        <PageContainer>
            <HStack pb={4}>
                <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
                    <BreadcrumbItem>
                        <BreadcrumbLink as={Link} to={generatePath(ROUTES.LIST_INTEGRATIONS_PAGE)}>
                            Integrations
                        </BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem>
                        <BreadcrumbLink>
                            {storeIntegrations[0]?.NostraAppIntegration?.name ?? "No Stores Found"}
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            </HStack>
            <Stack
                justifyContent="space-between"
                pb={{ base: "spacer-2", md: "spacer-6" }}
                alignItems={{ base: "none", md: "center" }}
                direction={{ base: "column", md: "row" }}
            >
                <Header>{storeIntegrations[0]?.NostraAppIntegration?.name ?? "No Stores Found"}</Header>
                {storeIntegrations?.length === 0 ? null : (
                    <Search
                        data={storeIntegrations}
                        setFilteredData={setFilteredIntegrations}
                        setSearchIsLoading={setSearchIsLoading}
                        filterFunction={searchIntegrationsFilter}
                    />
                )}
            </Stack>
            {searchIsLoading ? (
                <LoadingPage />
            ) : filteredIntegrations?.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    justifyContent="center"
                    display="flex"
                    style={{ marginTop: "8rem" }}
                >
                    No Stores Found For This Integration
                </Text>
            ) : (
                <div style={{ height: "calc(100vh - 200px)" }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => {
                            const ITEM_SPACING = 8;
                            const ITEM_SIZE = 100;
                            return (
                                <List
                                    height={height}
                                    width={width}
                                    itemCount={filteredIntegrations.length}
                                    itemSize={ITEM_SIZE + ITEM_SPACING}
                                >
                                    {({ index, style }) => {
                                        const integration = filteredIntegrations[index];
                                        return (
                                            <Link
                                                to={generatePath(ROUTES.LIST_INTEGRATIONS_BY_STORE, {
                                                    customerUrl: integration.store.customerUrl,
                                                })}
                                            >
                                                <Flex
                                                    style={{ ...style, height: ITEM_SIZE }}
                                                    key={integration.id}
                                                    padding={"spacer-2"}
                                                    border={"1px"}
                                                    borderColor={"gray.200"}
                                                    borderRadius={"0.75rem"}
                                                    width={"100%"}
                                                    overflow={"auto"}
                                                >
                                                    <Avatar name={integration.store.customerUrl} />
                                                    <Box ml="3">
                                                        <HStack>
                                                            <Text fontWeight="bold">
                                                                {integration.store.customerUrl}
                                                            </Text>
                                                            {integration.enabled ? (
                                                                <Badge colorScheme={"green"}>Enabled</Badge>
                                                            ) : (
                                                                <Badge colorScheme={"red"}>Disabled</Badge>
                                                            )}
                                                        </HStack>
                                                        <HStack alignContent={"center"} pt="spacer-2" pl="spacer-1">
                                                            <Text textStyle={"text-body-meta-bold"}>Started At:</Text>
                                                            <Text textStyle={"text-body-meta-regular"}>
                                                                {formatDateTimeDisplay(integration.startedAt)}
                                                            </Text>
                                                        </HStack>
                                                        <HStack alignContent={"center"} pl="spacer-1">
                                                            <Text textStyle={"text-body-meta-bold"}>Ended At:</Text>
                                                            <Text textStyle={"text-body-meta-regular"}>
                                                                {formatDateTimeDisplay(integration.endedAt)}
                                                            </Text>
                                                        </HStack>
                                                    </Box>
                                                </Flex>
                                            </Link>
                                        );
                                    }}
                                </List>
                            );
                        }}
                    </AutoSizer>
                </div>
            )}
        </PageContainer>
    );
};
