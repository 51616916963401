import { Text, useToast, Stack } from "@chakra-ui/react";
import { useListSingleStoreIntegrations } from "../../hooks";
import { Store, SingleStoreIntegration } from "../../types";
import { PageContainer } from "../PageContainer";
import { LoadingPage } from "../LoadingPage";
import { AlertMessage, Search } from "../../components";
import { useEffect, useState } from "react";
import { IntegrationsTabUpdateForm } from "./IntegrationsTabUpdateForm";

type Props = {
    store: Store;
};

const searchIntegrationsFilter = (integration: SingleStoreIntegration, searchTerm: string) => {
    return integration.NostraAppIntegration.name.toLowerCase().includes(searchTerm);
};

export const IntegrationsTab = ({ store }: Props) => {
    const { data: storeIntegrations, isLoading, isError } = useListSingleStoreIntegrations(store.id, store.customerUrl);
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const [filteredIntegrations, setFilteredIntegrations] = useState<SingleStoreIntegration[]>([]);

    useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });
    const showToast = useToast();

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description: "An unexpected error has occurred. If the problem persists, please contact support.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    if (isLoading) return <LoadingPage />;

    return (
        <PageContainer padding={{ base: 0, md: "spacer-7" }} maxWidth={"1440px"}>
            <Stack
                justifyContent="space-between"
                pb={{ base: "spacer-2", md: "spacer-6" }}
                alignItems={{ base: "none", md: "center" }}
                direction={{ base: "column", md: "row" }}
            >
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                >
                    Integrations
                </Text>
                <Search
                    data={storeIntegrations}
                    setFilteredData={setFilteredIntegrations}
                    setSearchIsLoading={setSearchIsLoading}
                    filterFunction={searchIntegrationsFilter}
                />
            </Stack>
            <AlertMessage status="warning" mb="spacer-6">
                Contact engineering to onboard a new integration.
            </AlertMessage>
            {searchIsLoading ? (
                <LoadingPage />
            ) : filteredIntegrations?.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    justifyContent="center"
                    display="flex"
                    style={{ marginTop: "8rem" }}
                >
                    No Integrations Found For This Store
                </Text>
            ) : (
                <>
                    {filteredIntegrations.map((integration: SingleStoreIntegration) => {
                        return (
                            <IntegrationsTabUpdateForm
                                integration={integration}
                                key={integration.id}
                                customerUrl={store.customerUrl}
                            />
                        );
                    })}
                </>
            )}
        </PageContainer>
    );
};
