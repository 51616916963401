import { Stack, Text, useMediaQuery, Flex, Avatar, useToast, VStack } from "@chakra-ui/react";
import { PageContainer } from "../PageContainer";
import { Header, Search, SubHeader, Link } from "../../components";
import { LoadingPage } from "../LoadingPage";
import { useState, useEffect } from "react";
import { useListAllIntegrations } from "../../hooks";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import { generatePath } from "react-router-dom";
import { ROUTES } from "../../Routes";
import { AllIntegrations, Integrations } from "../../types";

const searchIntegrationsFilter = (integration: Integrations, searchTerm: string) =>
    integration.name.toLowerCase().includes(searchTerm);

export const ListIntegrationsPage = () => {
    const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
    const [filteredIntegrations, setFilteredIntegrations] = useState<AllIntegrations>([]);
    const { data: integrationsList, isLoading, isError } = useListAllIntegrations();
    const [isMobile] = useMediaQuery("(max-width: 744px)");

    useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });
    const showToast = useToast();

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description: "An unexpected error has occurred. If the problem persists, please contact support.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    if (isLoading) return <LoadingPage />;
    if (!integrationsList) return <SubHeader>No Integrations Found</SubHeader>;

    return (
        <PageContainer>
            <Stack
                justifyContent="space-between"
                pb={{ base: "spacer-2", md: "spacer-6" }}
                alignItems={{ base: "none", md: "center" }}
                direction={{ base: "column", md: "row" }}
            >
                <Header>Integrations</Header>
                <Search
                    data={integrationsList}
                    setFilteredData={setFilteredIntegrations}
                    setSearchIsLoading={setSearchIsLoading}
                    filterFunction={searchIntegrationsFilter}
                />
            </Stack>
            {searchIsLoading ? (
                <LoadingPage />
            ) : filteredIntegrations?.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    justifyContent="center"
                    display="flex"
                    style={{ marginTop: "8rem" }}
                >
                    No Integrations Found
                </Text>
            ) : (
                <div style={{ height: "calc(100vh - 200px)" }}>
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => {
                            const ITEM_SPACING = 8;
                            const ITEM_SIZE = isMobile ? 160 : 110;
                            return (
                                <List
                                    height={height}
                                    width={width}
                                    itemCount={filteredIntegrations.length}
                                    itemSize={ITEM_SIZE + ITEM_SPACING}
                                >
                                    {({ index, style }) => {
                                        const integration = filteredIntegrations[index];
                                        return (
                                            <Link
                                                to={generatePath(ROUTES.LIST_STORES_PER_INTEGRATION, {
                                                    integrationId: integration.id,
                                                })}
                                            >
                                                <Flex
                                                    style={{ ...style, height: ITEM_SIZE }}
                                                    key={integration.id}
                                                    padding={"spacer-2"}
                                                    border={"1px"}
                                                    borderColor={"gray.200"}
                                                    borderRadius={"0.75rem"}
                                                    width={"100%"}
                                                    overflow={"auto"}
                                                >
                                                    <Avatar name={integration.name} />
                                                    <VStack justifyContent={"start"} align={"flex-start"} pl={3}>
                                                        <Text fontWeight="bold">{integration.name}</Text>
                                                        <Text>{integration.category}</Text>
                                                        <Text textStyle={"text-body-meta-bold"} ml="spacer-1">
                                                            Enabled Integrations:{" "}
                                                            {integration._count.EcomStoreNostraAppIntegration}
                                                        </Text>
                                                    </VStack>
                                                </Flex>
                                            </Link>
                                        );
                                    }}
                                </List>
                            );
                        }}
                    </AutoSizer>
                </div>
            )}
        </PageContainer>
    );
};
