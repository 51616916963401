import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { useParams } from "react-router";
import { StorePerIntegration } from "../../types";

export const useListStoresPerIntegration = () => {
    const params = useParams<Record<string, string>>();
    const integrationId = params.integrationId!;
    return useQuery(["integrationsByStore", integrationId], async () => await listStoresPerIntegration(integrationId));
};

const listStoresPerIntegration = async (integrationId: string): Promise<StorePerIntegration[]> => {
    const listActiveIntegrations = `${SVC_URLS["portal"]}/integrations/${integrationId}/stores`;
    const options = await fetchOptions();
    const response = await fetch(listActiveIntegrations, options).catch(handleErrors);
    return fetchResponse(response);
};
