import {
    HStack,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    Tab,
    TabList,
    TabPanels,
    Tabs,
    useToast,
    VStack,
    useMediaQuery,
} from "@chakra-ui/react";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Header, SubHeader, StoreSwitchHeader } from "../../components";
import { useAuthenticatedUser, useStore } from "../../hooks";
import { Store, TabPaths } from "../../types";
import { NotFound404 } from "../ErrorPages/NotFound404";
import { LoadingPage } from "../LoadingPage";
import { PageContainer } from "../PageContainer";
import { subdomainOf } from "../../lib";
import { useNavigate, Outlet, useLocation, generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../../Routes";

export const EditStorePage = () => {
    const storeQuery = useStore();
    const { store, isLoading } = useData(storeQuery);
    const { isCloudOpsRole, isAdminRole, isOwnerRole } = useAuthenticatedUser();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams<Record<string, string>>();
    const customerUrl = params.customerUrl ? params.customerUrl : store?.customerUrl;
    const [currentTab, setCurrentTab] = useState("");
    const [tabPathsLoaded, setTabPathsLoaded] = useState(false);
    const [isMobile] = useMediaQuery("(max-width: 744px)");

    const tabPaths: TabPaths = useMemo(() => {
        let initialTabPaths: TabPaths = {};
        if (isCloudOpsRole && store) {
            initialTabPaths = {
                General: "general",
                "Cache Settings": "cache-settings",
                "Session Content": "session-content",
                ...(store && store?.workerPlatform === "shopify"
                    ? { Shopify: "shopify" }
                    : { Salesforce: "salesforce" }),
                Integrations: "integrations",
                History: "history",
                "Store Settings": "store-settings",
                Permissions: "permissions",
            };
        } else if (isAdminRole && store) {
            initialTabPaths = {
                "Store Settings": "store-settings",
            };
        } else if (isOwnerRole && store) {
            initialTabPaths = {
                "Store Settings": "store-settings",
                Permissions: "permissions",
            };
        }
        store && setTabPathsLoaded(true);
        return initialTabPaths;
    }, [isCloudOpsRole, isAdminRole, isOwnerRole, store]);

    useEffect(() => {
        if (tabPathsLoaded) {
            const path = location.pathname.split("/").pop();
            const matchingKey = Object.keys(tabPaths).find((key) => tabPaths[key] === path);
            const initialTab = matchingKey ? tabPaths[matchingKey] : Object.keys(tabPaths)[0];

            const basePath = generatePath(ROUTES.STORE_EDIT, { customerUrl });
            if (!matchingKey && currentTab === "") {
                setCurrentTab(initialTab);
                navigate(`${basePath}/${tabPaths[initialTab]}`, { replace: true });
            }
            if (matchingKey) {
                setCurrentTab(matchingKey);
            }
        }
    }, [location.pathname, tabPaths, navigate, customerUrl, tabPathsLoaded, currentTab]);

    const handleTabChange = (index: string) => {
        const basePath = generatePath(ROUTES.STORE_EDIT, { customerUrl });
        const path = tabPaths[index];
        navigate(basePath + "/" + path);
    };

    /** Loading State */
    if (isLoading) return <LoadingPage />;

    /** Data not found */
    if (store === undefined) return <NotFound404 />;

    const subdomain = subdomainOf(store.ecomUrl);

    return (
        <PageContainer>
            <HStack align={"baseline"}>
                <VStack alignItems={"baseline"} width="100%">
                    <HStack justifyContent={"space-between"} width={"100%"} pb="spacer-1">
                        <Header>Edit Store</Header>
                        <StoreSwitchHeader orgId={store.orgId} currentStore={store.customerUrl} />
                    </HStack>
                    <Tabs
                        isLazy
                        width="100%"
                        index={Object.keys(tabPaths).indexOf(currentTab)}
                        onChange={(index) => handleTabChange(Object.keys(tabPaths)[index])}
                    >
                        <TabList>
                            <SubHeader pb="spacer-0" mr="auto">
                                {isCloudOpsRole ? (
                                    <Menu>
                                        <MenuButton>
                                            <ChevronDownIcon />
                                            <span className="trackCustomerUrl">{store.customerUrl}</span>
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem
                                                icon={<ExternalLinkIcon />}
                                                as="a"
                                                target="_blank"
                                                href={`https://admin.shopify.com/store/${subdomain}`}
                                            >
                                                Shopify Admin
                                            </MenuItem>
                                            <MenuItem
                                                icon={<ExternalLinkIcon />}
                                                as="a"
                                                target="_blank"
                                                href={`https://${store.customerUrl}`}
                                            >
                                                {store.customerUrl}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                ) : (
                                    <span className="trackCustomerUrl">{store.customerUrl}</span>
                                )}
                            </SubHeader>
                            {Object.keys(tabPaths).map((tabName) => {
                                return <Tab key={tabName}>{tabName}</Tab>;
                            })}
                        </TabList>
                        <TabPanels pt={isMobile ? "spacer-3" : 0}>
                            <Outlet />
                        </TabPanels>
                    </Tabs>
                </VStack>
            </HStack>
        </PageContainer>
    );
};

const useData = (storeQuery: UseQueryResult<Store, unknown>) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const store = storeQuery.data;
    const isLoading = storeQuery.isLoading;
    const isError = storeQuery.isError;

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description: "There was an error loading this page. Please try again in a few moments.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    return {
        store,
        isLoading,
    };
};
