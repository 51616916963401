import { Text, VStack, Input, Checkbox, Button, HStack, FormControl, useToast } from "@chakra-ui/react";
import { formatDateTimeDisplay } from "../../lib";
import { FieldSet, InputJson } from "../../components";
import { useForm } from "react-hook-form";
import { useStoreIntegrationMutation, useEnqueueDeploymentMutation } from "../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { JSONString, SingleStoreIntegration } from "../../types";

type Props = {
    integration: SingleStoreIntegration;
    customerUrl: string;
};

type SingleStoreIntegrationForm = {
    name: string;
    enabled: boolean;
    createdAt: string;
    startedAt: string;
    endedAt: string;
    configuration?: JSONString;
};

export const IntegrationsTabUpdateForm = ({ integration, customerUrl }: Props) => {
    const defaultValues: SingleStoreIntegrationForm = buildDefaultValues(integration);
    const storeIntegrationMutation = useStoreIntegrationMutation(integration.storeId, customerUrl, integration.id);
    const client = useQueryClient();
    const enqueueDeployment = useEnqueueDeploymentMutation({});

    useToast({
        variant: "subtle",
        duration: 5000,
        isClosable: true,
    });
    const showToast = useToast();

    const {
        register,
        setValue,
        formState: { errors },
        watch,
        handleSubmit,
        reset,
    } = useForm<SingleStoreIntegrationForm>({
        defaultValues: defaultValues,
    });

    // Reset form values when integration data updates
    useEffect(() => {
        if (integration) {
            reset(buildDefaultValues(integration));
        }
    }, [integration, reset]);

    const handleIntegrationEnabledChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setValue("enabled", true);
        } else {
            setValue("enabled", false);
        }
    };

    const handleUpdateIntegration = (formData: SingleStoreIntegrationForm) => {
        const requestBody = {
            enabled: formData.enabled,
        };

        storeIntegrationMutation.mutate(requestBody, {
            onSuccess: () => {
                client.invalidateQueries({
                    queryKey: ["singleStoreIntegrations", customerUrl],
                });
                enqueueDeployment.mutate(customerUrl, {
                    onSuccess: () => {
                        showToast({
                            title: "Success",
                            description: "Integration updated successfully. The changes have been placed in the queue.",
                            status: "success",
                        });
                    },
                    onError: () => {
                        showToast({
                            title: "Error",
                            description:
                                "An unexpected error occurred while placing the changes in the queue. If the problem persists, please contact support.",
                            status: "error",
                        });
                    },
                });
            },
            onError: () => {
                showToast({
                    title: "Error",
                    description:
                        "An unexpected error occurred while updating this integration. If the problem persists, please contact support.",
                    status: "error",
                });
            },
        });
    };

    return (
        <FormControl maxWidth={{ md: "100%" }}>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(handleUpdateIntegration)} id={integration.id}>
                <FieldSet legend={integration.NostraAppIntegration.name}>
                    <VStack justify={"start"} align={"flex-start"} pl={0}>
                        <Checkbox
                            py={"spacer-2"}
                            spacing="4"
                            size="md"
                            id="enabled"
                            onChange={handleIntegrationEnabledChange}
                            isChecked={watch("enabled")}
                        >
                            Integration Enabled
                        </Checkbox>

                        <Text pt={"spacer-2"}>Onboard Date</Text>
                        <Input
                            id="createdAt"
                            width={{ base: "90%", lg: "50%" }}
                            size="md"
                            type="text"
                            isReadOnly
                            defaultValue={defaultValues.createdAt}
                            {...register("createdAt")}
                        />

                        <Text pt={"spacer-2"}>Started At</Text>
                        <Input
                            width={{ base: "90%", lg: "50%" }}
                            type="text"
                            id="startedAt"
                            isReadOnly
                            defaultValue={defaultValues.startedAt}
                            {...register("startedAt")}
                        />
                        <Text pt={"spacer-2"}>Ended At</Text>
                        <Input
                            width={{ base: "90%", lg: "50%" }}
                            type="text"
                            id="endedAt"
                            isReadOnly
                            defaultValue={defaultValues.endedAt}
                            {...register("endedAt")}
                        />
                        {/* TODO: Update configuration to accept/validate values */}
                        <Text pt={"spacer-2"}>Configuration</Text>
                        <div style={{ width: "100%" }}>
                            <InputJson
                                {...{
                                    name: "configuration",
                                    placeholder: JSON.stringify(integration.configuration, null, 2),
                                    formSettings: {
                                        register,
                                        setValue,
                                        errors,
                                        watch,
                                    },
                                    isReadOnly: true,
                                }}
                            ></InputJson>
                        </div>
                    </VStack>
                    <HStack justify={"end"}>
                        <Button colorScheme={"button-primary"} ml="auto" type="submit">
                            Submit
                        </Button>
                    </HStack>
                </FieldSet>
            </form>
        </FormControl>
    );
};

const buildDefaultValues = (integration: SingleStoreIntegration) => ({
    name: integration.NostraAppIntegration.name,
    enabled: integration.enabled,
    createdAt: formatDateTimeDisplay(integration.createdAt),
    startedAt: formatDateTimeDisplay(integration.startedAt),
    endedAt: formatDateTimeDisplay(integration.endedAt),
});
