import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { AllIntegrations } from "../../types";

export const useListAllIntegrations = () => {
    return useQuery(["integrations"], async () => await listAllIntegrations());
};

const listAllIntegrations = async (): Promise<AllIntegrations> => {
    const listIntegrations = `${SVC_URLS["portal"]}/integrations`;
    const options = await fetchOptions();
    const response = await fetch(listIntegrations, options).catch(handleErrors);
    return fetchResponse(response);
};
