import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions, handleErrors, fetchResponse } from "../../lib";
import { SingleStoreIntegration } from "../../types";

export const useListSingleStoreIntegrations = (storeId: string, customerUrl: string) => {
    return useQuery(
        ["singleStoreIntegrations", customerUrl],
        async () => await listSingleStoreIntegrations(storeId, customerUrl),
    );
};

const listSingleStoreIntegrations = async (storeId: string, customerUrl: string): Promise<SingleStoreIntegration[]> => {
    const singleStoreIntegrations = `${SVC_URLS["portal"]}/${storeId}/integrations`;
    const options = await fetchOptions({
        method: "GET",
        headers: { Store: customerUrl },
    });
    const response = await fetch(singleStoreIntegrations, options).catch(handleErrors);
    return fetchResponse(response);
};
