import { HStack, VStack, Flex, Divider, Accordion, useTheme, Text } from "@chakra-ui/react";
import { ROUTES } from "../Routes";
import { Link, MenuItemDropDown } from "../components";
import { HamburgerMenu } from "./HamburgerMenu";
import { useAuthenticatedUser } from "../hooks";
import { useNavigate, generatePath, useLocation } from "react-router-dom";
import { DocumentationMenu } from "./DocumentationMenu";
import { IconFlag2 } from "@tabler/icons-react";

export const ApplicationSideBar = () => {
    const { isCloudOpsRole, isOwnerRole, isInternalRole, orgId } = useAuthenticatedUser();

    return (
        <MenuContainer>
            <HamburgerMenu />
            <Flex direction={"column"} display={{ base: "none", md: "flex" }}>
                <Accordion allowToggle>
                    <MenuItem to={ROUTES.HOME}>
                        <Link to={ROUTES.HOME}>Home</Link>
                    </MenuItem>
                    <MenuItem to={ROUTES.LIST_STORES}>
                        <Link to={ROUTES.LIST_STORES}>Stores</Link>
                    </MenuItem>
                    <MenuItem to={ROUTES.STATUS_PAGE}>
                        <Link to={ROUTES.STATUS_PAGE}>Nostra Status</Link>
                    </MenuItem>
                    {isCloudOpsRole ? (
                        <Accordion allowToggle>
                            <MenuItemDropDown
                                menuItems={[
                                    <MenuItem to={generatePath(ROUTES.ORGANIZATION, { orgId })}>
                                        <Link to={generatePath(ROUTES.ORGANIZATION, { orgId })}>My Organization</Link>
                                    </MenuItem>,
                                    <MenuItem to={ROUTES.ALL_ORGANIZATIONS}>
                                        <Link to={ROUTES.ALL_ORGANIZATIONS}>All Organizations</Link>
                                    </MenuItem>,
                                ]}
                            >
                                Organizations
                            </MenuItemDropDown>
                        </Accordion>
                    ) : null}
                    {isOwnerRole ? (
                        <MenuItem to={generatePath(ROUTES.ORGANIZATION, { orgId })}>
                            <Link to={generatePath(ROUTES.ORGANIZATION, { orgId })}>Organization</Link>
                        </MenuItem>
                    ) : null}

                    <DocumentationMenu />
                    <MenuItem to={ROUTES.GET_HELP_NOW}>
                        <Link to={ROUTES.GET_HELP_NOW}>
                            <HStack justifyContent={"start"}>
                                <IconFlag2 stroke={"1.25"} fill={"red"} />
                                <Text textStyle={"text-body-display-XS"}>Troubleshoot</Text>
                            </HStack>
                        </Link>
                    </MenuItem>
                    <MenuItem isExternal to={ROUTES.SUPPORT}>
                        <Link target="_blank" to={ROUTES.SUPPORT}>
                            Contact Support
                        </Link>
                    </MenuItem>

                    {isInternalRole ? (
                        <>
                            <Divider mb={"spacer-2"} mt={"spacer-2"} />
                            <MenuItem to={ROUTES.INSTALLED_APPS}>
                                <Link to={ROUTES.INSTALLED_APPS}>Installed Apps</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.SHOP}>
                                <Link to={ROUTES.SHOP}>Shop</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.ALL_STORES_REPORT}>
                                <Link to={ROUTES.ALL_STORES_REPORT}>All Stores Report</Link>
                            </MenuItem>
                        </>
                    ) : null}

                    {isCloudOpsRole ? (
                        <>
                            <Divider mb={"spacer-2"} mt={"spacer-2"} />
                            <MenuItem to={ROUTES.CREATE_CUSTOMER}>
                                <Link to={ROUTES.CREATE_CUSTOMER}>New Customer</Link>
                            </MenuItem>

                            <MenuItem to={ROUTES.LIST_BULLETINS_PAGE}>
                                <Link to={ROUTES.LIST_BULLETINS_PAGE}>Bulletins</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.INSTALLED_APPS}>
                                <Link to={ROUTES.INSTALLED_APPS}>Installed Apps</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.SHOP}>
                                <Link to={ROUTES.SHOP}>Shop</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.MONITORING_PAGE}>
                                <Link to={ROUTES.MONITORING_PAGE}>Edge Monitoring</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.STORE_REPORTS}>
                                <Link to={ROUTES.STORE_REPORTS}>Reports</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.ALL_STORES_REPORT}>
                                <Link to={ROUTES.ALL_STORES_REPORT}>All Stores Report</Link>
                            </MenuItem>
                            <MenuItem to={ROUTES.LIST_INTEGRATIONS_PAGE}>
                                <Link to={ROUTES.LIST_INTEGRATIONS_PAGE}>Integrations</Link>
                            </MenuItem>
                        </>
                    ) : null}
                </Accordion>
            </Flex>
        </MenuContainer>
    );
};

type MenuContainerProps = {
    children?: React.ReactNode;
};

const MenuContainer = ({ children }: MenuContainerProps) => {
    return (
        <VStack
            align={{ base: "flex-start" }}
            padding={"spacer-3"}
            paddingTop={"spacer-4"}
            bgColor={{ base: "transparent", md: "background-action-default" }}
            h={{ base: "100%", md: "94vh" }}
            position={{ base: "relative", md: "fixed" }}
            overflowY={{ base: "visible", md: "auto" }}
            pb="spacer-8"
        >
            {children}
        </VStack>
    );
};

type MenuItemProps = {
    children?: React.ReactNode;
    to: string;
    isExternal?: boolean;
};

export const MenuItem = ({ children, to = "/", isExternal = false }: MenuItemProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isActive = location.pathname === to;
    const theme = useTheme();

    return (
        <HStack
            padding={"spacer-3"}
            gap={"spacer-3"}
            width={"13rem"}
            height={"40px"}
            maxWidth={"100%"}
            _hover={{
                backgroundColor: "background-action-active",
                borderRadius: "8px",
                cursor: "pointer",
            }}
            onClick={() => {
                isExternal ? window.open(to, "_blank") : navigate(to);
            }}
            style={
                isActive
                    ? {
                          marginTop: 0,
                          backgroundColor: `color-mix(in srgb, ${theme.colors["background-action-active"]}, transparent 40%)`,
                          borderRadius: "8px",
                      }
                    : { marginTop: 0 }
            }
        >
            {children}
        </HStack>
    );
};

// const MenuDivider = () => {
//   return (
//     <StackDivider
//       height={"1px"}
//       width={"222px"}
//       left={"0px"}
//       top={"251px"}
//       borderRadius={"0px"}
//       padding={"0px 0px 0px 24px"}
//       bgColor={"#D2D9E9"}
//       // borderColor="gray.200"
//     />
//   );
// };
